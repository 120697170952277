import { configureStore } from "@reduxjs/toolkit";

import { 
  categoryListSlice, getAllCategoryByNameSlice, getSubcatItemsSlice, filterItemsSlice,
  updateCategorySlice, createCategorySlice, deleteCategorySlice,
  updateSubCategorySlice,createSubCategorySlice, deleteSubCategorySlice
} from "./slices/categorySlices";
import { 
  itemDetailSlice, reviewItemSlice, getNewcollectionSlice, getAllItemsSlice, updateItemSlice,
  deleteItemSlice, createItemSlice
 } from "./slices/itemSlices";
import { 
  userLoginSlice, userRegisterSlice, getUserDetailsSlice, updateUserProfileSlice,
  userListSlice, getUserByIdDetailsSlice, updateUserSlice, deleteUserSlice
 } from "./slices/userSlices";
import { addtoCartSlice } from "./slices/cartSlices";
import { 
  createOrderSlice, orderDetailsSlice, listMyOrdersSlice, payOrderSlice, deliverOrderlice,
  listOrdersSlice
} from "./slices/orderSlices";
import { 
  getBrandsSlice, brandItemsSlice,
  updateBrandSlice, createBrandSlice, deleteBrandSlice
 } from "./slices/brandSlices";

const reducer = {
  categoryList: categoryListSlice.reducer,
  getBrands: getBrandsSlice.reducer,
  brandItems: brandItemsSlice.reducer,

  updateBrand: updateBrandSlice.reducer,
  createBrand: createBrandSlice.reducer,
  deleteBrand: deleteBrandSlice.reducer,
  
  getAllCategoryByName: getAllCategoryByNameSlice.reducer,
  getSubcatItems: getSubcatItemsSlice.reducer,

  filterItems: filterItemsSlice.reducer,
  updateCategory: updateCategorySlice.reducer,
  createCategory: createCategorySlice.reducer,
  deleteCategory: deleteCategorySlice.reducer,

  updateSubCategory: updateSubCategorySlice.reducer,
  createSubCategory: createSubCategorySlice.reducer,
  deleteSubCategory: deleteSubCategorySlice.reducer,
  
  itemDetail: itemDetailSlice.reducer,
  reviewItem: reviewItemSlice.reducer,
  getNewcollection: getNewcollectionSlice.reducer,
  getAllItems: getAllItemsSlice.reducer,
  updateItem: updateItemSlice.reducer,
  deleteItem: deleteItemSlice.reducer,
  createItem: createItemSlice.reducer,
  
  cart: addtoCartSlice.reducer,
  
  loginUser: userLoginSlice.reducer,
  registerUser: userRegisterSlice.reducer,
  userDetails: getUserDetailsSlice.reducer,
  updateUserProfile: updateUserProfileSlice.reducer,
  userList: userListSlice.reducer,
  userByIdDetails: getUserByIdDetailsSlice.reducer,
  updateUser: updateUserSlice.reducer,
  deleteUser: deleteUserSlice.reducer,
  
  orderCreate: createOrderSlice.reducer,
  orderDetails: orderDetailsSlice.reducer,
  orderListMy: listMyOrdersSlice.reducer,
  payOrder: payOrderSlice.reducer,
  deliverOrder: deliverOrderlice.reducer,
  listOrders: listOrdersSlice.reducer,
}

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
  JSON.parse(localStorage.getItem('cartItems')) : [];
const userInfoFromStorage = localStorage.getItem('userInfo') ?
  JSON.parse(localStorage.getItem('userInfo')) : null;
const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
  JSON.parse(localStorage.getItem('shippingAddress')) : {};
const paymentMethodFromStorage = localStorage.getItem('paymentMethod') ?
  JSON.parse(localStorage.getItem('paymentMethod')) : null;

const brandsFromSessionStorage = sessionStorage.getItem('brands') ?
  JSON.parse(sessionStorage.getItem('brands')) : [];

const preloadedState = {
  cart: { 
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  loginUser: { userInfo: userInfoFromStorage },
  getBrands: { brands: brandsFromSessionStorage },
}

const store = configureStore({
  reducer,
  preloadedState
});

export type AppDispatch = typeof store.dispatch

export default store;