import axios from "axios";
import { createAsyncThunk, Action } from "@reduxjs/toolkit";

export const itemDetail = createAsyncThunk(
  'itemDetail',
  async (id) => {
    try {
      const { data } = await axios.get(`/api/items/${id}`);
      return data;
    } catch (error) {
      throw Error(error)
    }
  }
);

export const reviewItemAction = (itemId, review) => (async (dispatch, getState): Action => {
  try {
    dispatch({ type: 'review_create_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/items/${itemId}/reviews/`, review, config);
    
    dispatch({
      type: 'review_create_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'review_create_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

export const getNewcollectionAction = () => (async (dispatch): Action => {
  try {
    dispatch({ type: 'newcollection_create_request' });
    
    const config = {
      headers: {
        'Content-type': 'application/json'
      }
    };
    
    const { data } = await axios.get(`/api/items/newcollection/`, config);
    
    dispatch({
      type: 'newcollection_create_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'newcollection_create_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Get All Items for admin panel
export const getAllItemsAction = (keyword) => (async (dispatch): Action => {
  try {
    dispatch({ type: 'get_allitems_request' });
    
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8'
      }
    };
    
    const { data } = await axios.get(`/api/items/${keyword}`, config);
    
    dispatch({
      type: 'get_allitems_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'get_allitems_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Update Item for admin panel
export const updateItemAction = (item) => (async (dispatch, getState): Action => {
  
  try {
    dispatch({ type: 'update_item_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.patch(`/api/items/update/${item._id}/`, item, config);
    
    dispatch({
      type: 'update_item_success',
      payload: data
    });
    
    dispatch(itemDetail.fulfilled);
  
  } catch (e) {
    dispatch({
      type: 'update_item_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Delete Item for admin panel
export const deleteItemAction = (id) => (async (dispatch, getState): Action => {
  
  try {
    dispatch({ type: 'delete_item_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.delete(`/api/items/delete/${id}/`, config);
    
    dispatch({
      type: 'delete_item_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'delete_item_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Create Item for admin panel
export const createItemAction = (item) => (async (dispatch, getState): Action => {
  
  try {
    dispatch({ type: 'create_item_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/items/create/`, item, config);
    
    dispatch({
      type: 'create_item_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'create_item_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});