import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, batch } from 'react-redux';

import LoadImage from "../components/LoadImage.js";

import { listCategories } from '../actions/categoryActions.js';
import { getBrandsAction } from '../actions/brandActions.js';
import Loader from '../components/Loader.js';
import Message from '../components/Message.js';

import type { AppDispatch } from "@/store";

function HomeScreen() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [statecollapse, setStatecollapse] = useState(false);
  const [statecollapse1, setStatecollapse1] = useState(false);
  
  const { loading, category, error } = useSelector(state => state.categoryList);
  const { brands } = useSelector(state => state.getBrands);
  
  useEffect(() => {
    // Promise.all([dispatch(listCategories()), dispatch(getBrandsAction())]);
    batch(() => {
      dispatch(listCategories());
      dispatch(getBrandsAction());
    });
    /* dispatch(listCategories()).unwrap().then(() => {
      dispatch(getBrandsAction());
    }); */
  }, [dispatch])
  
  return (
    <div>
      <h1 className="text-center text-light mb-sm-4 h2 fst-italic">Home Page</h1>
      <div className='border-bottom border-secondary pb-sm-3 position-relative'>
        <span className='text-dark position-absolute h4 fw-bolder'
          style={{ marginTop: '5rem', left: '45%' }}>
          New collection 2022
        </span>
        <button className='btn btn-warning position-absolute hoverWarningButton'
          style={{ top: '50%', left: '45%' }} onClick={() => {navigate('newcollection')}}>
          View more
        </button>
        {/* <LoadImage
          loading='lazy'
          className='d-block mx-auto'
          placeholderImg='/static/images/advrs/800x600.png'
          src="/static/images/advrs/advrs.svg"
          style={{ maxWidth: '1800px' }} /> */}
        <picture>
          <source type="image/webp" srcSet="/static/images/advrs/advrs.webp"/>
          <source type="image/png" srcSet="/static/images/advrs/advrs.png" />
          <LoadImage
            className='d-block mx-auto'
            placeholderImg='/static/images/advrs/800x600.png'
            src="/static/images/advrs/advrs.png"
            style={{ maxWidth: '100%' }} /> 
          {/* <img src="/static/images/advrs/advrs.png" alt="" style={{ maxWidth: '1800px' }}/> */}
        </picture>
        {/* <object type="image/svg+xml">
          <img src='/static/images/advrs/advrs.svg' className='d-block mx-auto'
            alt="home advertising" style={{ maxWidth: '1800px' }} />
        </object> */}
      </div>
      
      <div className="row p-2 my-3 mx-auto" style={{ maxWidth: '1800px' }}>
        <div className="col-lg col-sm-auto">
          <div className="card-body brands">
            <h5 className="card-title fw-lighter">What happend on the lake</h5>
            <div className="card-text mb-2 fw-light text-light">
              Some quick example text to build on the card title and make up the bulk of the card's content.
              <div className="collapse" id="collapseExample">In the initial volume of the First Series, “The Rover Boys at School,” I introduced my readers to Dick.Tom and Sam Rover and their friends and relatives. This book and those which immediately followed related the adventures of the three Rover boys at Putnam Hall Military Academy, Brill College and while on many outings.</div>
            </div>
            <Link to='#collapseExample' className='text-decoration-none'
              onClick={() => setStatecollapse(prev => !prev)}
              data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">
              Read {statecollapse ? 'less' : 'more'}
            </Link>
          </div>
        </div>
        <div className="col-lg col-sm-auto">
          <div className="card-body brands">
            <h5 className="card-title fw-lighter">Something About the Rovers</h5>
            <div className="card-text mb-2 fw-light text-light">
              Some quick example text to build on the card title and make up the bulk of the card's content.
              <div className="collapse" id="collapseExample1" >
                Having graduated from college, the three young men established themselves in business in New York City and became married to their girl sweethearts. Dick Rover was blessed with a son and a daughter, as was likewise his brother Sam, while Tom Rover became the proud father of twin boys. As the four youths were of a lively disposition.
              </div>
            </div>
            <Link className='text-decoration-none' onClick={() => setStatecollapse1(prev => !prev)}
              data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample1"
              to='#collapseExample1'>
              Read {statecollapse1 ? 'less' : 'more'}
            </Link>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-light fst-italic text-center mt-sm-2">Popular Brands</h3>
        <div style={{ maxWidth: '1800px' }}
          className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5 mx-auto">
          {
            brands.map((brand) => (
              <div className="col d-flex justify-content-center mb-4 brands" key={brand._id}>
                <Link to={`${brand.slug}/items`} style={{ maxWidth: 'min-content', alignSelf: 'flex-end' }}
                  className="list-group-item-action bg-transparent w-auto mx-sm-5 d-block text-center text-decoration-none text-light">
                  <LoadImage className='mb-2' src={`/static${brand.image}`} />
                  <span style={{ fontSize: '1.1em' }}>{brand.title}</span>
                </Link>
              </div>
            ))
          }
        </div>
      </div>
      <div>
        <h3 className="text-light fst-italic text-center mt-sm-2 mb-sm-4">Categories</h3>
        <div className="container-fluid" style={{ maxWidth: '1800px' }}>
          {error &&  <Message varinat={'danger'}>{error.message}</Message>}
          {
            loading ? <Loader /> : (
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {category.map(c => (
                  <div className="col" key={c._id}>
                    <div className="card mb-3 categorycards">
                      <h3 className="card-header text-center">
                        <Link to={c.slug}>{c.title}</Link>
                      </h3>
                      <picture>
                        <source type="image/webp" srcSet={`/static${c.image.split('.')[0]}${'.webp'}`} />
                        <source type="image/png" srcSet={`/static${c.image}`} />
                        <LoadImage
                          style={{ width: '100%' }}
                          src={`/static${c.image}`} />
                      </picture>
                      {/* <LoadImage src={`/static${c.image}`} /> */}
                      <div className="card-body">
                        <p className="card-text">
                          {c.metadesc}
                        </p>
                      </div>
                      <div className="card-body text-center">
                        <Link className="card-link btn btn-info" to={c.slug}>View</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default React.memo(HomeScreen);