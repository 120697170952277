import React, { useCallback, useEffect, useState } from "react";

function LoadImage({ src, placeholderImg, ...props }) {
  const [imgSrc, setSrc] = useState((placeholderImg) || (src ?? '/static/images/logo.svg'));
  
  const onLoad = useCallback(() => {
    return setSrc(src);
  }, [src]);
  
  const alt = imgSrc.split('/')
  
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.addEventListener("load", onLoad);
    return () => {
      img.removeEventListener("load", onLoad);
    };
  }, [src, onLoad]);
  
  return <img {...props} src={imgSrc} alt={alt[alt.length - 1].split('.')[0]} />;
};

export default LoadImage;