import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// Hook for scrolling to top of window after moving to another page.
const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
    
  }, [pathname]);
  
  return null;
};

export default ScrollToTop;
